$(document).ready(function () {

    // imgLiquid init
    $(".img-fill").imgLiquid({
        fill: true,
        horizontalAlign: "center",
        verticalAlign: "center"
    });

    $(".img-no-fill").imgLiquid({
        fill: false,
        horizontalAlign: "center",
        verticalAlign: "center"
    });

    $(".main-menu").on("click", "a", function (event) {

        event.preventDefault();
        var id = $(this).attr('href');
        //узнаем высоту от начала страницы до блока на который ссылается якорь
        var top = $(id).offset().top;
        //анимируем переход на расстояние - top за 1500 мс
        $('body,html').animate({scrollTop: top}, 500);

    });


    $('.phoneInput').mask('+7 (000) 000-00-00');

    $('.fancybox').fancybox();

    var mySwiper = new Swiper('.swiper-container', {
        speed: 400,
        spaceBetween: 100,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    // $('#formRequestModal').modal();

});